import React from 'react';
import './UnableGasAlert.css'

const UnableGasAlert = () => {
    return (
        <div className='unable-gas-alert'>
            <span>На сегодняшний день Мосгаз не выдает технические условия на газификацию. Как только ситуация решится мы обязательно разместим информацию на нашем сайте!</span>
        </div>
    );
};

export default UnableGasAlert;