import React, {useEffect, useState} from 'react';
import './Calc.css'
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const Calc = () => {
    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')

    useEffect(() => {
        if(sended)setTextBtn('Наш менеджер скоро с вами свяжется! ')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Расчитать');
    }, [empty,error,sended]);


    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);


    const sendHandler = ()=>{
        if(!sended){
            if (
                document.getElementById('calc_input1').value === ''
                || document.getElementById('calc_input2').value === ''
                || document.getElementById('calc_input3').value === ''
                || document.getElementById('calc_input4').value === ''
                || document.getElementById('calc_input_day').value === ''
                || document.getElementById('calc_input_month').value === ''
                || document.getElementById('calc_input_year').value === ''
                || phone === ''

            ){
                setEmpty(true)
                setPhone('')
                document.getElementById('calc_input1').value = ''
                document.getElementById('calc_input2').value = ''
                document.getElementById('calc_input3').value = ''
                document.getElementById('calc_input4').value = ''
                document.getElementById('calc_input_day').value = ''
                document.getElementById('calc_input_month').value = ''
                document.getElementById('calc_input_year').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://dk-profi.com/api/mailer.php?' +
                    'object_type=' + document.getElementById('object_type').value +
                    '&address=' + document.getElementById('calc_input1').value +
                    '&number_of_square_meters=' + document.getElementById('calc_input2').value +
                    '&insert_place=' + document.getElementById('calc_input3').value +
                    '&calc_input_day=' + document.getElementById('calc_input_day').value +
                    '&calc_input_month=' + document.getElementById('calc_input_month').value +
                    '&calc_input_year=' + document.getElementById('calc_input_year').value +
                    '&presure=' + document.getElementById('presure').value +
                    '&distance_to_gas_main=' + document.getElementById('calc_input4').value +
                    '&calc_phone=' + document.getElementById('calc_phone').value + '&action=Calculator' ,
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }


    return (
        <div className={'calc'}>
            <div className={'calc__text-filed'}>
                <h2>Расчитайте
                    ориентировочную
                    стоимость услуг
                </h2>
                <p>Хотите узнать, сколько будет стоить подключение газа
                    к частному дому? Наш калькулятор поможет вам оценить
                    примерные расходы на услуги по газификации дома.
                    Стоимость строительно-монтажных работ зависит
                    от нескольких ключевых факторов, таких как:</p>
                <span className={'calc__text-filed__arrow'}></span>
            </div>
            <div className={'calc__calc-filed'}>
                <div className={'calc__calc-filed__left-filed'}>
                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Тип объекта</h4>
                        <select className={'input_w100'} id="object_type">
                            <option selected value="Тип объекта">Тип объекта</option>
                            <option value="Частный дом">Частный дом</option>
                            <option value="Таунхаус">Таунхаус</option>
                            <option value="Промышленные объекты">Промышленные объекты</option>
                        </select>
                    </div>

                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Адрес объекта</h4>
                        <input id={'calc_input1'} placeholder={'Введите адрес'} className={'input_w100'} type="text"/>
                    </div>

                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Количество квадратных метров</h4>
                        <input id={'calc_input2'} placeholder={'Введите примерную площадь'} className={'input_w100'}
                               type="text"/>
                    </div>

                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Место врезки в газопровод</h4>
                        <input id={'calc_input3'} placeholder={'Место врезки в газопровод'} className={'input_w100'}
                               type="text"/>
                    </div>

                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Дата проведения работ</h4>
                        <div className={'calc__calc-filed__input-group_date-box'}>
                            <input id={'calc_input_day'} placeholder={'день'} max={'31'} min={'1'} type="number"/>
                            <input id={'calc_input_month'} placeholder={'мес.'} max={'12'} min={'1'} type="number"/>
                            <input id={'calc_input_year'} placeholder={'год'} max={'2030'} min={'2024'} type="number"/>
                        </div>

                    </div>

                </div>
                <div className={'calc__calc-filed__right-filed'}>
                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Давление в газопроводе</h4>
                        <select className={'input_w100'} id="presure">
                        <option selected value="не указано">Давление</option>
                            <option value="0.6-1.2">Св. 0.6 до 1.2 МПа</option>
                            <option value="0.3-0.6">От 0.3 до 0.6 МПа</option>
                            <option value="0.005-0.3 ">От 0.005 до 0.3 МПа</option>
                            <option value="0.005">До 0.005 МПа</option>
                        </select>
                    </div>
                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Растояние до газовой магистрали (м)</h4>
                        <input id={'calc_input4'} placeholder={'Растояние в метрах'} min={1} className={'input_w100'} type="number"/>
                    </div>
                    <div className={'calc__calc-filed__input-group'}>
                        <h4>Телефон</h4>
                        <ReactInput
                            className={'input_w100'}
                            value={phone}
                            onChange={setPhone}
                            parse={parse}
                            format={format}
                            placeholder={'Номер телефона'}
                            inputMode={'tel'}
                            id={'calc_phone'}
                        />
                    </div>
                    <div className={'calc__calc-filed__text-group'}>
                        <p>Нажмите кнопку, чтобы увидеть
                            примерную стоимость всех работ
                            и оборудования на основе ваших
                            данных.
                            <br/><br/>
                            Прикрепите документы, если у вас
                            уже есть необходимые разрешения
                        </p>
                    </div>

                    <div className={'calc__calc-filed__button-group'}>
                        <button onClick={sendHandler}>{textBtn}</button>
                        <label htmlFor="fileInput" className="file-label">
                            <input name={'file'} type="file" id="fileInput" style={{visibility:'hidden'}}/>
                        </label>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Calc;