import React, {createContext, useContext} from 'react';
import {Route, Routes} from "react-router";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Portfolio from "./pages/Portfolio/Portfolio";
import Services from "./pages/Services/Services";
import Notfound from "./pages/Notfound/Notfound";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import UnableGasAlert from "./components/UnableGasAlert/UnableGasAlert";
import './modal.css'

export const ContentContext = createContext(null);
function App() {


    const content = {
        about_id:['4','3','2'],
        ip_name: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДК-ПРОФИ"',
        inn: '7751254403',
        ogrn:'1237700328843',
        phone: '+7 985-911-35-26',
        email: 'info@dk-profi.com',
        address:'Московская область, город Апрелевка, Апрелевская ул., 65Б/2,',
        coordinates: [55.536451, 37.154877],
        work_time:'Пн-Пт: 09.00-17.00',
        link_inst:'https://www.instagram.com/dk_profi?igsh=Y25wMzV5ZTZ4amZq&utm_source=qr',
        link_tg:'https://t.me/dk_profi_gaz',
        link_vk:'/contacts',
        services: [
            {
                id:'2',
                name:'Проектирование газопроводов',
                desc_short:'Проектирование газопроводов, учитывающее все технические и нормативные требования',
                desc_long: 'Проектирование газопроводов, учитывающее все технические и нормативные требования',
                cost: 'Подайте заявку для уточнения стоимости',
                img:[
                    'https://dk-profi.com/api/img/services/services1.png',
                    'https://dk-profi.com/api/img/services/services2.png'
                ]
            },
            {
                id:'3',
                name:'Строительство газопровода',
                desc_short:'Строительство газопровода от точки подключения до газоиспользующего оборудования',
                desc_long:'Строительство газопровода от точки подключения до газоиспользующего оборудования, обеспечивающее надежность и безопасность эксплуатации.',
                cost: 'Подайте заявку для уточнения стоимости',
                img:[
                    'https://dk-profi.com/api/img/services/services6.png',
                    'https://dk-profi.com/api/img/services/services4.png'
                ]
            },
            {
                id:'4',
                name:'Выполнение пусконаладочных работ',
                desc_short:'Выполнение пусконаладочных работ для газового оборудования',
                desc_long: 'Выполнение пусконаладочных работ для газового оборудования, гарантируя его правильное функционирование и долгий срок службы.',
                cost: 'Подайте заявку для уточнения стоимости',
                img:[
                    'https://dk-profi.com/api/img/services/services5.png',
                    'https://dk-profi.com/api/img/services/services7.png'
                ]
            },
            {
                id:'5',
                name:'Консультация',
                desc_short:'Консультации на ранних этапах до начала строительства объекта капитального строительства',
                desc_long:'Консультации на ранних этапах до начала строительства объекта капитального строительства, помогая вам принять обоснованные решения и избежать возможных проблем в будущем.',
                cost: 'Подайте заявку для уточнения стоимости',
                img:[
                    'https://dk-profi.com/api/img/services/services3.png'
                ]
            },
            {
                id:'6',
                name:'Вынос газопровода из пятна застройки',
                desc_short:'Позволяет избежать пересечений и конфликтов с проектируемыми или существующими строениями',
                desc_long:'Вынос газопровода из пятна застройки, что позволяет избежать пересечений и конфликтов с проектируемыми или существующими строениями.',
                cost: 'Подайте заявку для уточнения стоимости',
                img:[
                    'https://dk-profi.com/api/img/services/services8.png'
                ]
            }
        ],
        portfolio: [
            {
                id:'1',
                name:'Выполнение пусконаладочных работ',
                desc_short:'В загородном доме были выполнены пусконаладочные работы',
                desc_long:'В загородном доме были выполнены пусконаладочные работы',
                cost: '30 000 ₽',
                img:[
                    'https://dk-profi.com/api/img/portfolio/job_1.png',
                    'https://dk-profi.com/api/img/portfolio/job_2.png'
                ]
            }
        ]
    }




    return (
   <>
       <ContentContext.Provider value={content}>
           <Header/>
           <Routes>
               <Route path={'/'} element={<Home/>}/>
               <Route path={'/about'} element={<About/>}/>
               <Route path={'/contacts'} element={<Contacts/>}/>
               <Route path={'/portfolio'} element={<Portfolio/>}/>
               <Route path={'/services'} element={<Services/>}/>
               <Route path={'*'} element={<Notfound/>}/>
           </Routes>
           <Footer/>
       </ContentContext.Provider>

   </>
  );
}

export default App;
