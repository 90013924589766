import React, {useEffect, useState} from 'react';
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const SimpleFeedBackMini = ({service_name = 'any'}) => {
    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')

    useEffect(() => {
        if(sended)setTextBtn('Заявка отправлена!')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Отправить заявку');
    }, [empty,error,sended]);


    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);

    const sendHandler = ()=>{
        if(!sended){
            if ( document.getElementById('FeedbackForm__nameInput').value === '' || phone === ''){
                setEmpty(true)
                setPhone('')
                document.getElementById('FeedbackForm__nameInput').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://dk-profi.com/api/mailer.php?name=' + document.getElementById('FeedbackForm__nameInput').value +
                    '&phone=' + phone +
                    '&serviceName=' + service_name +
                    '&action=miniForm' ,
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }



    return (
        <div className={'simpleFeedback__feedback-form'}>
            <input id={'FeedbackForm__nameInput'} placeholder={'Имя'} type="text"/>
            <ReactInput
                value={phone}
                onChange={setPhone}
                parse={parse}
                format={format}
                placeholder={'Номер телефона'}
                inputMode={'tel'}
            />
            <p>Нажимая кнопку “Отправить”, вы подтверждаете согласие на обработку персональных данных</p>
            <button onClick={sendHandler}>{textBtn}</button>
        </div>
    );
};

export default SimpleFeedBackMini;