import React from 'react';
import './Banner.css'
import {NavLink} from "react-router-dom";

const Banner = () => {


    return (
        <NavLink to={'/services'}>
            <div className={'banner'}>
                <img className={'banner__img'} src="/img/XT3gGKIJk5k.jpg" alt=""/>
                <div className={'banner__text-field'}>
                    <h2>Оказываем полный цикл работ</h2>
                    <p>Полный спектр услуг "под ключ" – от проектирования до подключения и обслуживания газопроводов.
                        С "ДК-Профи" ваш объект будет теплым и комфортным в кратчайшие сроки!</p>
                    <span className={'banner__icon'}></span>
                </div>
            </div>
        </NavLink>

    );
};

export default Banner;