import React, {useState} from 'react';
import './CardPortfolio.css'
import {Modal} from "react-bootstrap";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import PageTitle from "../PageTitle/PageTitle";
import SimpleFeedBackMini from "../SimpleFeedBackMini/SimpleFeedBackMini";

const CardPortfolio = ({image, title, description, description_long, cost, id}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Modal
                size="lg"
                aria-labelledby={"contained-modal-title-vcenter_portfolio_" + id}
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton >
                    <Modal.Title id={"contained-modal-title-vcenter_portfolio_" + id}>
                        Описание
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Splide>
                        {
                            image.map((value, index)=>{
                                return(
                                    <SplideSlide key={index + '_slide_id' + id}>
                                        <img src={value} alt="Image"/>
                                    </SplideSlide>
                                )
                            })
                        }
                    </Splide>
                    <PageTitle title={title} text={description_long}/>
                    <p className={'modal__coast'}>{cost}</p>

                </Modal.Body>
                <Modal.Footer>
                    <SimpleFeedBackMini/>
                </Modal.Footer>
            </Modal>
            <div onClick={handleShow} className={'CardPortfolio'}>
                <span className={'CardPortfolio__icon'}></span>
                <img className={'CardPortfolio__image'} src={image[0]}
                     alt=" "/>
                <div className={'CardPortfolio__text-box'}>
                    <h2 className={'CardPortfolio__title'}>{title}</h2>
                    <p className={'CardPortfolio__description'}>
                        {description}
                    </p>
                    <p className={'CardPortfolio__coast'}>{cost}</p>
                </div>
            </div>
        </>

    );
};

export default CardPortfolio;