import React, {useEffect, useState} from 'react';
import './Header.css'
import {NavLink, useLocation} from "react-router-dom";
import {Offcanvas} from "react-bootstrap";


const Header = () => {
    const [opacity, setOpacity] = useState(false)

    const setActive = ({isActive})=> isActive ? "header-navigate__link header-navigate__link_active" : 'header-navigate__link'
    const location = useLocation();
    const scrollTop= ()=>{
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }

    useEffect(()=>{

        if (location.pathname === '/'){
            setOpacity(true)
        }else {
            setOpacity(false)
        }
    },[location])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const offcanvasRedirect = ()=>{
        handleClose()
        scrollTop()
    }

    return (
        <>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Навигация</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <nav className="header-navigate_offcanvas">
                        <NavLink onClick={offcanvasRedirect} to={"/"} className={setActive}>Главная</NavLink>
                        <NavLink onClick={offcanvasRedirect} to={"/about"} className={setActive}>О компании</NavLink>
                        <NavLink onClick={offcanvasRedirect} to={"/services"} className={setActive}>Услуги</NavLink>
                        <NavLink onClick={offcanvasRedirect} to={"/portfolio"} className={setActive}>Портфолио</NavLink>
                        <NavLink onClick={offcanvasRedirect} to={"/contacts"} className={setActive}>Контакты</NavLink>
                    </nav>
                    <a href="/" className={'download_link'}>Политика конфиденциальности</a>
                </Offcanvas.Body>
            </Offcanvas>
            <header className={opacity ? "header_opacity-true" : "header_opacity-false"}>
                <div className="content">
                    <a href="/" className="logo"></a>
                    <span onClick={handleShow} className={'list'}></span>
                    <nav className="header-navigate">
                        <NavLink onClick={scrollTop} to={"/"} className={setActive}>Главная</NavLink>
                        <NavLink onClick={scrollTop} to={"/about"} className={setActive}>О компании</NavLink>
                        <NavLink onClick={scrollTop} to={"/services"} className={setActive}>Услуги</NavLink>
                        <NavLink onClick={scrollTop} to={"/portfolio"} className={setActive}>Портфолио</NavLink>
                        <NavLink onClick={scrollTop} to={"/contacts"} className={setActive}>Контакты</NavLink>
                    </nav>
                </div>
            </header>
        </>

    );
};

export default Header;