import React from 'react';
import './notFound.css'
import {NavLink} from "react-router-dom";

const Notfound = () => {
    return (
        <div className={'notFound'}>
            <span className={'notFound__background'}></span>
            <p>404</p>
            <NavLink to={"/"}>НА ГЛАВНУЮ</NavLink>
        </div>
    );
};

export default Notfound;