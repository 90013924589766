import React, {useState} from 'react';
import './CardServices.css'
import {Button, Modal} from "react-bootstrap";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/sea-green';
import PageTitle from "../PageTitle/PageTitle";
import SimpleFeedback from "../SimpleFeedback/SimpleFeedback";
import SimpleFeedBackMini from "../SimpleFeedBackMini/SimpleFeedBackMini";

const CardServices = ({image, title, description, description_long, cost, id}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Modal
                size="lg"
                aria-labelledby={"contained-modal-title-vcenter_" + id}
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton >
                    <Modal.Title id={"contained-modal-title-vcenter_" + id}>
                        Описание
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Splide >
                        {
                            image.map((value, index)=>{
                                return(
                                    <SplideSlide key={index + '_slide_id' + id}>
                                        <img src={value} alt="Image"/>
                                    </SplideSlide>
                                )
                            })
                        }
                    </Splide>
                    <PageTitle title={title} text={description_long}/>
                    <p className={'modal__coast'}>{cost}</p>
                </Modal.Body>
                <Modal.Footer>
                   <SimpleFeedBackMini service_name={title}/>
                </Modal.Footer>
            </Modal>
            <div className={'cardServices'} onClick={handleShow}>
                <span className={'cardServices__icon'}></span>
                <div className={'cardServices__image-box'}>
                    <img className={'cardServices__image'} src={image[0]} alt=""/>
                </div>
                <div className={'cardServices__text-box'}>
                    <h2 className={'cardServices__title'}>{title}</h2>
                    <p className={'cardServices__description'}>{description}</p>
                    <p className={'cardServices__coast'}>{cost}</p>
                </div>
            </div>
        </>

    );
};

export default CardServices;