import React from 'react';
import './PageTitle.css'

const PageTitle = ({title, text = '', top = false}) => {


    return (
        <div className={ (top)?  'pageTitle top-margin' : 'pageTitle'}>
            <div className={'pageTitle__title-box'}>
                <h2 className={'pageTitle__title'}>{title}</h2>
                <span className={'pageTitle__line'}></span>
            </div>
            {(text.length > 0) ? <p className={'pageTitle__text'}>{text}</p> : ''}
        </div>
    );
};

export default PageTitle;