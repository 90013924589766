import React from 'react';
import './Background.css'
import firstImage from './img/firstImage.png'
import secondImage from './img/secondImage.png'

function Background(props) {
    return (
        <div className={'background-box'}>
            <div className={'background'}>
                <img src={firstImage} alt="" className="background__image-first"/>
                <img src={secondImage} alt="" className="background__image-second"/>
                <span className="background__pipe-left"></span>
                <span className="background__pipe-right"></span>
            </div>
        </div>
    );
}

export default Background;