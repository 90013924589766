import React, {useContext} from 'react';
import './portfolio.css'
import Background from "../../components/Background/Background";
import PageTitle from "../../components/PageTitle/PageTitle";
import CardPortfolio from "../../components/CardPortfolio/CardPortfolio";
import SimpleFeedback from "../../components/SimpleFeedback/SimpleFeedback";
import UnableGasAlert from "../../components/UnableGasAlert/UnableGasAlert";
import CardServices from "../../components/CardServices/CardServices";
import {ContentContext} from "../../App";
import { Helmet } from "react-helmet";


const Portfolio = () => {
    const content = useContext(ContentContext);
    return (
        <div className={'portfolio'}>
            <Helmet>
                <title>Проекты газификации- DK-Profi</title>
                <meta
                    name="description"
                    content="Газификации частных домов, сложных объектов, это всё наше портфолио - ДК-Профи"
                />
                <link rel="canonical" href="https://dk-profi.com/portfolio" />
            </Helmet>
            <UnableGasAlert/>
            <Background/>
            <main className={'portfolio__content'}>
            <PageTitle top title={'Портфолио'}/>
                <div className={'portfolio__card-box'}>
                    {content.portfolio.map((value, index)=>{
                        return <CardPortfolio
                            key={"card_" + index}
                            title={value.name}
                            cost={value.cost}
                            image={value.img}
                            description={value.desc_short}
                            description_long={value.desc_long}
                            id={value.id}
                        />
                    })}
                </div>
                <SimpleFeedback/>
            </main>
        </div>
);
};

export default Portfolio;